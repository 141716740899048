<template>
  <b-card title="aaaa" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">none</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(status)="data">
        <b-badge v-if="data.item.status === 'online'" variant="success">
          ออนไลน์
        </b-badge>
        <b-badge v-if="data.item.status === 'offline'" variant="danger">
          ออฟไลน์
        </b-badge>
      </template>
      <template #cell(ip)="data">
        <b-badge v-if="data.item.status === 'online'" variant="info">
          {{ data.item.ip }}
        </b-badge>
      </template>
      <template #cell(description)="data">
        <div
          @click="
            edit_data(data.item.id, data.item.description, data.item.SSID,data.item.download,data.item.upload,data.item.Session_Timeout,data.item.mac)
          "
        >
          {{ data.item.description }}
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <!-- <b-button
          @click="
            edit_data(data.item.id, data.item.description, data.item.SSID,data.item.download,data.item.upload,data.item.Session_Timeout,data.item.mac)
          "
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="EditIcon" />
        </b-button> -->
        &nbsp;&nbsp;
        <b-button
          @click="
            delete_data(data.item.id, data.item.mac, data.item.branch_name)
          "
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="DeleteIcon" />
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-modal
      id="modal-primary"
 
      modal-class="modal-primary"
      centered
      title="แก้ไข hotspot"
      v-model="model_show"
      hide-footer
    >
      <div>
        <br />
        <label for="ชื่อไวไฟ">ชื่อไวไฟ</label>
        <b-form-input v-model="ssid" /><br />
        <label for="description">description</label>
        <b-form-input v-model="description" />
      <br>
        <b-row>
          <b-col cols="12" >
            <label for="">ตั้งค่าเวลาการใช้งาน</label>
            <b-form-select
              v-model="selected"
              :options="options"
              size="sm"
              class="mt-1"
            />
          </b-col>
        </b-row><br>
        <b-row>
          <b-col >
            <p>
              Download <code>{{ upload }} Mbps</code>
            </p>
            <vue-slider v-model="upload" :lazy="true" />
          </b-col>
          <b-col>
            <p>
              Upload <code> {{ download }} Mbps</code>
            </p>
            <vue-slider
              v-model="download"
              :lazy="true"
            
            />
          </b-col>
        </b-row> <br>
      <div align="center">
        <b-button @click="save_edit()" variant="outline-primary">
          บันทึก
        </b-button>
      </div>

        <br /><br />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BInputGroupPrepend,
  BRow,
    BCol,
} from "bootstrap-vue";
import api from "@/api";
import Ripple from "vue-ripple-directive";
import VueSlider from "vue-slider-component";
export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    BModal,
    BInputGroupPrepend,
    VueSlider,
    BRow,
    BCol,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      model_show: false,
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      edit_id: null,
      description: null,
      ssid: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "SSID", label: "ชื่อ Wifi" },
        { key: "mac", label: "mac" },
        { key: "description", label: "รายละเอียด", sortable: true },
        // { key: "ip", label: "LAST IP" },
        { key: "status", label: "สถานะ", sortable: true },
        { key: "lastseen", label: "อัพเดตล่าสุด", sortable: true },
        { key: "actions" },
      ],

      items: [],
      selected: "1800",
      download: 100,
      upload: 100,
      options: [
        { value: "1800", text: "30 นาที" },
        { value: "3600", text: "1 ชั่วโมง" },
        { value: "7200", text: "2 ชั่วโมง" },
        { value: "14400", text: "4 ชั่วโมง" },
        { value: "28800", text: "6 ชั่วโมง" },
      ],
    };
  },
  computed: {
    // direction() {
    //   if (store.state.appConfig.isRTL) {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.dir = "rtl";
    //     return this.dir;
    //   }
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.dir = "ltr";
    //   return this.dir;
    // },
 
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.loaddata();
    // this.totalRows = this.items.length
  },
  methods: {
    edit_data(eid, description, ssid,download,upload,Session_Timeout,mac) {
      this.model_show = true;
      this.description = description;
      this.ssid = ssid;
      console.log("ssid :>> ", ssid);
      this.edit_id = eid;
      this.download = download / 1000000;
      this.upload = upload / 1000000;
      this.selected = Session_Timeout;
      this.mac = mac;
 
    },
    save_edit() {
      // console.log("this.description  :>> ", this.description);
      // console.log("this.edit_id  :>> ", this.edit_id);
      this.edit_id;
      this.description;
      var userData = JSON.parse(localStorage.getItem("userData"));
      const params = {
        id: this.edit_id,
        description: this.description,
        download: this.download,
        upload: this.upload,
        time_use: this.selected,
        ssid: this.ssid,
        mac: this.mac,
        store_id: localStorage.getItem("store_id"),
        user_main: userData.user_main,
      };
      console.log('params :>> ', params);
      api
        .post("updatehotspot", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.loaddata();
          this.model_show = false;
          this.$swal({
            icon: "success",
            title: "แก้ไขข้อมูลสำเร็จ ",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch((error) => {
          this.model_show = false;
        });
    },
    delete_data(id, mac, branch_name) {
      // alert(id);
      this.$swal({
        title: "คุณต้องการลบ Device mac: " + mac,
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const params = {
            id: id,
          };
          api
            .post("deletehotspot", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.loaddata();
              this.$swal({
                icon: "success",
                title: "ลบ! Device mac: " + mac,
                text: "ข้อมูลของคุณถูกลบไปแล้ว",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {});
        }
      });
    },
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("gethotspot", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.items = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
.badge-success {
  background-color: rgb(3, 211, 48);
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>
